@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap');

h1{
   
    font-family: 'Bubblegum Sans', cursive;
}

.component p a{
    color: #007c89;
}

.component p a:hover{
    color: black;
}

@media only screen and (max-width: 690px){
    
}
