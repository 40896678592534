.containers{
    display: flex;
    width: 80%;
  
    margin: auto;
    border-radius: 0.5%;
   
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 16px 16px;
    
}


.para{
    margin-left: 7px;
    margin-right: 7px;
}
  
  /* On mouse-over, add a deeper shadow */
  .containers:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  


.site-image{
    width: 500px;
    height: 250px;
    vertical-align: center;
    object-fit: cover;
    border-radius: 2%;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

a{
    color: #007c89;
}


@media only screen and (max-width: 940px) {
    .containers{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .site-image{
      align-self: center; 
      justify-self: center;
    }
}