@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
}

main {
  /* rem is realtive to the font size on the rem element. You can see we just set that to 16px */
  margin-left: 5rem;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: #ffe01b;
  color: black;
  font-family: 'Bubblegum Sans', cursive;
}

.navbar:hover{
  background-color: white;
}

.brand-title {
  font-size: 1rem;
  margin: .5rem;
  height: 12%;
  width: 12%;
  
}

.navbar-links {
  height: 100%;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  display: block;
  text-decoration: none;
  color: black;
  padding: 1rem;
}

.navbar-links li a:hover {
  color: #007c89;
}

.toggle-button {
  position: absolute;
  top: .65rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  color: black;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .navbar {
      flex-direction: column;
      align-items: flex-start;
  }

  .toggle-button {
      display: flex;
  }

  .navbar-links {
      display: none;
      width: 100%;
  }

  .navbar-links ul {
      width: 100%;
      flex-direction: column;
  }

  .navbar-links ul li {
      text-align: center;
  }

  .navbar-links ul li:hover{
    background-color: rgb(220, 220, 220, 0.1);
   
  }

  .navbar-links ul li a {
      padding: .5rem 1rem;
  }

  .navbar-links.active {
      display: flex;
  }
  .active{
    display: flex;
  }

}

.rotate:hover{
  transform: rotateX(180deg);
}
.outer-container{
    background-color: #ffe01b;
    height: 500px;
    margin-top: 0px;
    position: absolute;
    z-index: -1;
    min-width: 100vw;
}

.container{
    max-height: 300px;
    margin-top: 100px;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    background-color: #ffe01b;
    font-family: Helvetica,Arial,Verdana,sans-serif;
    flex-wrap: wrap;
}

.side-text{
    max-width: 25%;
    margin-left: 200px;
    justify-content: center;
    font-family: Georgia,Times,serif;
}
.side-text h1 {
    font-family: 'Bubblegum Sans', cursive;
}

.container.side-image{
   max-width: 25%;
   margin-right: 200px;
    border-radius: 40%;
}

.blue-text{
    color: #007c89;
    cursor: pointer;
}

.blue-text:hover{
    color: black;
    cursor: pointer;
}

.pink-text{
    color: #df49a6;
}

.container .side-text p a{
    color: #007c89;
    cursor: pointer;
}

.container .side-text p a:hover{
    color: black;
    cursor: pointer;
}

.container .side-image{
    display: block;
    margin: auto;
    max-height: 400px;
    max-width: 25%;
}

.button-change{
    position: relative; 
    right: 38px;
    top: 13px;
}

@media only screen and (max-width: 591px) {
    .container{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .side-text{
        max-width: 75%;
        min-width: 40%;
        margin: auto;
        justify-content: center;
   
    }
   .container.side-image{
       max-width: 75%;
       min-width: 40%;
       margin: auto;
       border-radius: 40%;
    }
  }
/* Main Button  */
.main-btn{
    color: white;
    background-color: #007c89;
    box-shadow: inset 0 0 0 0.0625rem #007c89;
    cursor: pointer;
    border: none;
    height: 66px;
    width: 132px;
    font-family: Georgia,Times,serif;
    font-size: 1.5rem;
    border-radius: 4%;
}

.main-btn:hover{
    background-color: #403b3b;
}


.grey-box{
    background-color: #403b3b;
    color: white;
    margin: auto;
    width: 80vw;
    overflow: hidden;
}

.grey-box h2{
    font-family: 'Bubblegum Sans', cursive;
}

.video{
    background-color: white;
    border-radius: 2%;
    margin: auto;
    padding-bottom: 8px;
    padding-right: 6px;
    padding-left: 2px;
    padding-top: 2px;
    box-shadow: 20px 20px 20px black;
    width:560px; 
    height: 315px; 
}

@media only screen and (max-width: 690px){
    .video{
        width: auto;
        min-width: 70%;
    }
}

h1{
   
    font-family: 'Bubblegum Sans', cursive;
}

.component p a{
    color: #007c89;
}

.component p a:hover{
    color: black;
}

@media only screen and (max-width: 690px){
    
}

.coinbase-feature{
    background-color: #fbeeca;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
   
}

.coinbase-feature h3 {
    font-family: 'Bubblegum Sans', cursive;
    font-size: 1.6rem;
 
}
.coinbase-feature .funk-text {
    font-family: 'Bubblegum Sans', cursive;
}



.btc-image{
    max-height: 50%;
    max-width: 45%;
}

.coin-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
}

.coin-text{
    max-width: 45%;
}


@media only screen and (min-width: 700px) {
    .coin-text{
        max-width: 75%;
 }
}


.containers{
    display: flex;
    width: 80%;
  
    margin: auto;
    border-radius: 0.5%;
   
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 16px 16px;
    
}


.para{
    margin-left: 7px;
    margin-right: 7px;
}
  
  /* On mouse-over, add a deeper shadow */
  .containers:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  


.site-image{
    width: 500px;
    height: 250px;
    vertical-align: center;
    object-fit: cover;
    border-radius: 2%;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

a{
    color: #007c89;
}


@media only screen and (max-width: 940px) {
    .containers{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .site-image{
      align-self: center; 
      justify-self: center;
    }
}
h2{
    font-family: 'Bubblegum Sans', cursive;
}

.contained{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.item{
    margin: 50px;
}
.footer{
    background-color: #fbeeca;
}

.links{
    list-style-type: none;
}

.list-series{
    display: flex;
    flex-direction: row;
    margin: auto;
   
    justify-content: center;
}


.list-header{
    margin: auto;
   padding-left: 40px;
}

.copyright{
    background-color: white;
    margin: 30px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 550px) {
    .list-series{
        flex-direction: column;
    }
}
.coinbase-feature{
    background-color: #fbeeca;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
   
}

.coinbase-feature h3 {
    font-family: 'Bubblegum Sans', cursive;
    font-size: 1.6rem;
 
}
.coinbase-feature .funk-text {
    font-family: 'Bubblegum Sans', cursive;
}



.btc-image{
    max-height: 50%;
    max-width: 45%;
}

.coin-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
}

.coin-text{
    max-width: 45%;
}


@media only screen and (min-width: 700px) {
    .coin-text{
        max-width: 75%;
 }
}


/* Main Button  */
.main-btn{
    color: white;
    background-color: #007c89;
    box-shadow: inset 0 0 0 0.0625rem #007c89;
    cursor: pointer;
    border: none;
    height: 66px;
    width: 132px;
    font-family: Georgia,Times,serif;
    font-size: 1.5rem;
    border-radius: 4%;
}

.main-btn:hover{
    background-color: #403b3b;
}


.grey-box{
    background-color: #403b3b;
    color: white;
    margin: auto;
    width: 80vw;
    overflow: hidden;
}

.grey-box h2{
    font-family: 'Bubblegum Sans', cursive;
}

.video{
    background-color: white;
    border-radius: 2%;
    margin: auto;
    padding-bottom: 8px;
    padding-right: 6px;
    padding-left: 2px;
    padding-top: 2px;
    box-shadow: 20px 20px 20px black;
    width:560px; 
    height: 315px; 
}

@media only screen and (max-width: 690px){
    .video{
        width: auto;
        min-width: 70%;
    }
}

.containers{
    display: flex;
    width: 80%;
  
    margin: auto;
    border-radius: 0.5%;
   
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 16px 16px;
    
}


.para{
    margin-left: 7px;
    margin-right: 7px;
}
  
  /* On mouse-over, add a deeper shadow */
  .containers:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  


.site-image{
    width: 500px;
    height: 250px;
    vertical-align: center;
    object-fit: cover;
    border-radius: 2%;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

a{
    color: #007c89;
}


@media only screen and (max-width: 940px) {
    .containers{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .site-image{
      align-self: center; 
      justify-self: center;
    }
}
.outer-container{
    background-color: #ffe01b;
    height: 500px;
    margin-top: 0px;
    position: absolute;
    z-index: -1;
    min-width: 100vw;
}

.container{
    max-height: 300px;
    margin-top: 100px;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    background-color: #ffe01b;
    font-family: Helvetica,Arial,Verdana,sans-serif;
    flex-wrap: wrap;
}

.side-text{
    max-width: 25%;
    margin-left: 200px;
    justify-content: center;
    font-family: Georgia,Times,serif;
}
.side-text h1 {
    font-family: 'Bubblegum Sans', cursive;
}

.container.side-image{
   max-width: 25%;
   margin-right: 200px;
    border-radius: 40%;
}

.blue-text{
    color: #007c89;
    cursor: pointer;
}

.blue-text:hover{
    color: black;
    cursor: pointer;
}

.pink-text{
    color: #df49a6;
}

.container .side-text p a{
    color: #007c89;
    cursor: pointer;
}

.container .side-text p a:hover{
    color: black;
    cursor: pointer;
}

.container .side-image{
    display: block;
    margin: auto;
    max-height: 400px;
    max-width: 25%;
}

.button-change{
    position: relative; 
    right: 38px;
    top: 13px;
}

@media only screen and (max-width: 591px) {
    .container{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .side-text{
        max-width: 75%;
        min-width: 40%;
        margin: auto;
        justify-content: center;
   
    }
   .container.side-image{
       max-width: 75%;
       min-width: 40%;
       margin: auto;
       border-radius: 40%;
    }
  }
h2{
    font-family: 'Bubblegum Sans', cursive;
}

.contained{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.item{
    margin: 50px;
}
.footer{
    background-color: #fbeeca;
}

.links{
    list-style-type: none;
}

.list-series{
    display: flex;
    flex-direction: row;
    margin: auto;
   
    justify-content: center;
}


.list-header{
    margin: auto;
   padding-left: 40px;
}

.copyright{
    background-color: white;
    margin: 30px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 550px) {
    .list-series{
        flex-direction: column;
    }
}
#dark_code{
    position: absolute;
}

.outer-container{
    background-color: #ffe01b;
    height: 500px;
    margin-top: 0px;
    position: absolute;
    z-index: -1;
    min-width: 100vw;
}

.container{
    max-height: 300px;
    margin-top: 100px;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    background-color: #ffe01b;
    font-family: Helvetica,Arial,Verdana,sans-serif;
    flex-wrap: wrap;
}

.side-text{
    max-width: 25%;
    margin-left: 200px;
    justify-content: center;
    font-family: Georgia,Times,serif;
}
.side-text h1 {
    font-family: 'Bubblegum Sans', cursive;
}

.container.side-image{
   max-width: 25%;
   margin-right: 200px;
    border-radius: 40%;
}

.blue-text{
    color: #007c89;
    cursor: pointer;
}

.blue-text:hover{
    color: black;
    cursor: pointer;
}

.pink-text{
    color: #df49a6;
}

.container .side-text p a{
    color: #007c89;
    cursor: pointer;
}

.container .side-text p a:hover{
    color: black;
    cursor: pointer;
}

.container .side-image{
    display: block;
    margin: auto;
    max-height: 400px;
    max-width: 25%;
}

.button-change{
    position: relative; 
    right: 38px;
    top: 13px;
}

@media only screen and (max-width: 591px) {
    .container{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .side-text{
        max-width: 75%;
        min-width: 40%;
        margin: auto;
        justify-content: center;
   
    }
   .container.side-image{
       max-width: 75%;
       min-width: 40%;
       margin: auto;
       border-radius: 40%;
    }
  }

.containers{
    display: flex;
    width: 80%;
  
    margin: auto;
    border-radius: 0.5%;
   
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 16px 16px;
    
}


.para{
    margin-left: 7px;
    margin-right: 7px;
}
  
  /* On mouse-over, add a deeper shadow */
  .containers:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  


.site-image{
    width: 500px;
    height: 250px;
    vertical-align: center;
    object-fit: cover;
    border-radius: 2%;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

a{
    color: #007c89;
}


@media only screen and (max-width: 940px) {
    .containers{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .site-image{
      align-self: center; 
      justify-self: center;
    }
}
h2{
    font-family: 'Bubblegum Sans', cursive;
}

.contained{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.item{
    margin: 50px;
}

.three-images{
    max-width: 130px;
    max-height: 130px;
   
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: #ffe01b;
  color: black;
  font-family: 'Bubblegum Sans', cursive;
}

.navbar:hover{
  background-color: white;
}

.brand-title {
  font-size: 1rem;
  margin: .5rem;
  height: 12%;
  width: 12%;
  
}

.navbar-links {
  height: 100%;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  display: block;
  text-decoration: none;
  color: black;
  padding: 1rem;
}

.navbar-links li a:hover {
  color: #007c89;
}

.toggle-button {
  position: absolute;
  top: .75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .navbar {
      flex-direction: column;
      align-items: flex-start;
  }

  .toggle-button {
      display: flex;
  }

  .navbar-links {
      display: none;
      width: 100%;
  }

  .navbar-links ul {
      width: 100%;
      flex-direction: column;
  }

  .navbar-links ul li {
      text-align: center;
  }

  .navbar-links ul li a {
      padding: .5rem 1rem;
  }

  .navbar-links.active {
      display: flex;
  }
}

.rotate:hover{
  transform: rotateX(180deg);
}
.outer-container{
    background-color: #ffe01b;
    height: 500px;
    margin-top: 0px;
    position: absolute;
    z-index: -1;
    min-width: 100vw;
}

.container{
    max-height: 300px;
    margin-top: 100px;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    background-color: #ffe01b;
    font-family: Helvetica,Arial,Verdana,sans-serif;
    flex-wrap: wrap;
}

.pointer{
    cursor: pointer;
}

.side-text{
    max-width: 25%;
    margin-left: 200px;
    justify-content: center;
    font-family: Georgia,Times,serif;
}
.side-text h1 {
    font-family: 'Bubblegum Sans', cursive;
}

.container.side-image{
   max-width: 25%;
   margin-right: 200px;
    border-radius: 40%;
}

.blue-text{
    color: #007c89;
    cursor: pointer;
}

.blue-text:hover{
    color: black;
    cursor: pointer;
}

.pink-text{
    color: #df49a6;
}

.container .side-text p a{
    color: #007c89;
    cursor: pointer;
}

.container .side-text p a:hover{
    color: black;
    cursor: pointer;
}

.container .side-image{
    display: block;
    margin: auto;
    max-height: 400px;
    max-width: 25%;
}

.button-change{
    position: relative; 
    right: 38px;
    top: 13px;
}

@media only screen and (max-width: 591px) {
    .container{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .side-text{
        max-width: 75%;
        min-width: 40%;
        margin: auto;
        justify-content: center;
   
    }
   .container.side-image{
       max-width: 75%;
       min-width: 40%;
       margin: auto;
       border-radius: 40%;
    }
  }
/* Main Button  */
.main-btn{
    color: white;
    background-color: #007c89;
    box-shadow: inset 0 0 0 0.0625rem #007c89;
    cursor: pointer;
    border: none;
    height: 66px;
    width: 132px;
    font-family: Georgia,Times,serif;
    font-size: 1.5rem;
    border-radius: 4%;
}

.main-btn:hover{
    background-color: #403b3b;
}


.grey-box{
    background-color: #403b3b;
    color: white;
    margin: auto;
    width: 80vw;
    overflow: hidden;
}

.grey-box h2{
    font-family: 'Bubblegum Sans', cursive;
}

.video{
    background-color: white;
    border-radius: 2%;
    margin: auto;
    padding-bottom: 8px;
    padding-right: 6px;
    padding-left: 2px;
    padding-top: 2px;
    box-shadow: 20px 20px 20px black;
    width:560px; 
    height: 315px; 
}

@media only screen and (max-width: 690px){
    .video{
        width: auto;
        min-width: 70%;
    }
}

h1{
   
    font-family: 'Bubblegum Sans', cursive;
}

.component p a{
    color: #007c89;
}

.component p a:hover{
    color: black;
}

@media only screen and (max-width: 690px){
    
}

.coinbase-feature{
    background-color: #fbeeca;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
   
}

.coinbase-feature h3 {
    font-family: 'Bubblegum Sans', cursive;
    font-size: 1.6rem;
 
}
.coinbase-feature .funk-text {
    font-family: 'Bubblegum Sans', cursive;
}



.btc-image{
    max-height: 50%;
    max-width: 45%;
}

.coin-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
}

.coin-text{
    max-width: 45%;
}


@media only screen and (min-width: 700px) {
    .coin-text{
        max-width: 75%;
 }
}


.containers{
    display: flex;
    width: 80%;
  
    margin: auto;
    border-radius: 0.5%;
   
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 16px 16px;
    
}


.para{
    margin-left: 7px;
    margin-right: 7px;
}
  
  /* On mouse-over, add a deeper shadow */
  .containers:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  


.site-image{
    width: 500px;
    height: 250px;
    vertical-align: center;
    object-fit: cover;
    border-radius: 2%;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

a{
    color: #007c89;
}


@media only screen and (max-width: 940px) {
    .containers{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .site-image{
      align-self: center; 
      justify-self: center;
    }
}
h2{
    font-family: 'Bubblegum Sans', cursive;
}

.contained{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.item{
    margin: 50px;
}
.footer{
    background-color: #fbeeca;
}

.links{
    list-style-type: none;
}

.list-series{
    display: flex;
    flex-direction: row;
    margin: auto;
   
    justify-content: center;
}


.list-header{
    margin: auto;
   padding-left: 40px;
}

.copyright{
    background-color: white;
    margin: 30px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 550px) {
    .list-series{
        flex-direction: column;
    }
}
.all{
     width: 90vw;
     margin: auto;
}

ul li{
    justify-content: start;
}
.outer-container{
    background-color: #ffe01b;
    height: 500px;
    margin-top: 0px;
    position: absolute;
    z-index: -1;
    min-width: 100vw;
}

.container{
    max-height: 300px;
    margin-top: 100px;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    background-color: #ffe01b;
    font-family: Helvetica,Arial,Verdana,sans-serif;
    flex-wrap: wrap;
}

.side-text{
    max-width: 25%;
    margin-left: 200px;
    justify-content: center;
    font-family: Georgia,Times,serif;
}
.side-text h1 {
    font-family: 'Bubblegum Sans', cursive;
}

.container.side-image{
   max-width: 25%;
   margin-right: 200px;
    border-radius: 40%;
}

.blue-text{
    color: #007c89;
    cursor: pointer;
}

.blue-text:hover{
    color: black;
    cursor: pointer;
}

.pink-text{
    color: #df49a6;
}

.container .side-text p a{
    color: #007c89;
    cursor: pointer;
}

.container .side-text p a:hover{
    color: black;
    cursor: pointer;
}

.container .side-image{
    display: block;
    margin: auto;
    max-height: 400px;
    max-width: 25%;
}

.button-change{
    position: relative; 
    right: 38px;
    top: 13px;
}

@media only screen and (max-width: 591px) {
    .container{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .side-text{
        max-width: 75%;
        min-width: 40%;
        margin: auto;
        justify-content: center;
   
    }
   .container.side-image{
       max-width: 75%;
       min-width: 40%;
       margin: auto;
       border-radius: 40%;
    }
  }
/* Main Button  */
.main-btn{
    color: white;
    background-color: #007c89;
    box-shadow: inset 0 0 0 0.0625rem #007c89;
    cursor: pointer;
    border: none;
    height: 66px;
    width: 132px;
    font-family: Georgia,Times,serif;
    font-size: 1.5rem;
    border-radius: 4%;
}

.main-btn:hover{
    background-color: #403b3b;
}


.grey-box{
    background-color: #403b3b;
    color: white;
    margin: auto;
    width: 80vw;
    overflow: hidden;
}

.grey-box h2{
    font-family: 'Bubblegum Sans', cursive;
}

.video{
    background-color: white;
    border-radius: 2%;
    margin: auto;
    padding-bottom: 8px;
    padding-right: 6px;
    padding-left: 2px;
    padding-top: 2px;
    box-shadow: 20px 20px 20px black;
    width:560px; 
    height: 315px; 
}

@media only screen and (max-width: 690px){
    .video{
        width: auto;
        min-width: 70%;
    }
}

h1{
   
    font-family: 'Bubblegum Sans', cursive;
}

.component p a{
    color: #007c89;
}

.component p a:hover{
    color: black;
}

@media only screen and (max-width: 690px){
    
}

.containers{
    display: flex;
    width: 80%;
  
    margin: auto;
    border-radius: 0.5%;
   
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 16px 16px;
    
}


.para{
    margin-left: 7px;
    margin-right: 7px;
}
  
  /* On mouse-over, add a deeper shadow */
  .containers:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  


.site-image{
    width: 500px;
    height: 250px;
    vertical-align: center;
    object-fit: cover;
    border-radius: 2%;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

a{
    color: #007c89;
}


@media only screen and (max-width: 940px) {
    .containers{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .site-image{
      align-self: center; 
      justify-self: center;
    }
}
h2{
    font-family: 'Bubblegum Sans', cursive;
}

.contained{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.item{
    margin: 50px;
}
.footer{
    background-color: #fbeeca;
}

.links{
    list-style-type: none;
}

.list-series{
    display: flex;
    flex-direction: row;
    margin: auto;
   
    justify-content: center;
}


.list-header{
    margin: auto;
   padding-left: 40px;
}

.copyright{
    background-color: white;
    margin: 30px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 550px) {
    .list-series{
        flex-direction: column;
    }
}
