@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap');

h2{
    font-family: 'Bubblegum Sans', cursive;
}

.contained{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.item{
    margin: 50px;
}