@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Oleo+Script&display=swap');

/* Main Button  */
.main-btn{
    color: white;
    background-color: #007c89;
    box-shadow: inset 0 0 0 0.0625rem #007c89;
    cursor: pointer;
    border: none;
    height: 66px;
    width: 132px;
    font-family: Georgia,Times,serif;
    font-size: 1.5rem;
    border-radius: 4%;
}

.main-btn:hover{
    background-color: #403b3b;
}


.grey-box{
    background-color: #403b3b;
    color: white;
    margin: auto;
    width: 80vw;
    overflow: hidden;
}

.grey-box h2{
    font-family: 'Bubblegum Sans', cursive;
}

.video{
    background-color: white;
    border-radius: 2%;
    margin: auto;
    padding-bottom: 8px;
    padding-right: 6px;
    padding-left: 2px;
    padding-top: 2px;
    box-shadow: 20px 20px 20px black;
    width:560px; 
    height: 315px; 
}

@media only screen and (max-width: 690px){
    .video{
        width: auto;
        min-width: 70%;
    }
}
